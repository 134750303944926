import React, { Fragment } from "react"
import { Link, navigate } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import Button from "../Elements/Button"
import Brand from "../Elements/Brand"
import Layout from "../Layout/Layout"
import Container from "../Layout/Container"

import styles from "./utils/proofOfPayment.module.scss"
import useProofOfPaymentIcon from "./hooks/useProofOfPaymentIcon"

const ProofOfPaymentComplete = (props) => {
  const { location } = props
  const data = useProofOfPaymentIcon()

  return (
    <Layout title="Thank you!" seoTitle="Thank you">
      <Container isCentered>
        <div className={classNames("columns", styles["column"])}>
          <div className="column is-4-desktop is-12-mobile">
            <figure className={classNames("image mx-3")}>
              {data?.email?.childImageSharp?.fluid && (
                <Img
                  fluid={data.email.childImageSharp.fluid}
                  alt={`Proof of Payment Complete`}
                  className={styles["image"]}
                />
              )}
            </figure>
          </div>
          <div className="column">
            <Fragment>
              <p className="mb-2">
                Thank you for submitting your proof of payment.
              </p>
              <p className="mb-2">
                Please check{" "}
                {location?.state?.email ? (
                  <span className="has-text-weight-bold has-text-primary">
                    {location.state.email}
                  </span>
                ) : (
                  "your email"
                )}{" "}
                for your reference number. For faster processing, please provide
                your reference number if you have any order-related inquiries.
              </p>
              <p className="mb-2">
                You will receive an email confirming your request within the
                next working day. <Brand /> will update you when your medicines
                are ready for delivery.
              </p>
            </Fragment>
          </div>
        </div>
        <center>
          <Button
            onClick={() => navigate("/")}
            color="primary"
            className="is-medium"
          >
            Finish
          </Button>
        </center>
      </Container>
    </Layout>
  )
}

export default ProofOfPaymentComplete
